import EntitiesListWidgetsFactoryWithoutTypes from '@/views/apps/entities/widgetFactory/EntitiesListWidgetsFactoryWithoutTypes';
import PeopleListWidgetsFactory from '@/views/apps/people/widgetFactory/PeopleListWidgetsFactory';
import MembersListWidgetsFactory from '@/views/apps/member/widgetFactory/MembersListWidgetsFactory';
import ElementsListWidgetsFactory from '@/views/apps/elements/widgetFactory/ElementsListWidgetsFactory';
import EventsListWidgetsFactory from '@/views/apps/membershipEvents/widgetFactory/EventsListWidgetsFactory';
import OrganizationsListWidgetsFactory from '@/views/apps/organization/widgetFactory/OrganizationsListWidgetsFactory';
import ChallengesListWidgetsFactory from '@/views/apps/challenges/widgetFactory/ChallengesListWidgetFactory';
import CollectiveFilter from '@/views/apps/collectives/constants/CollectiveFilter';
import MediaType from '@/views/apps/media/constants/MediaType';

const AppsComponentsMap = Object.freeze({
  tasks: {
    loadComponent: () => import('@/views/apps/tasks/widgets/TasksListWidget.vue' /* webpackChunkName: "TasksListWidget" */),
    props: {
      perPage: 7,
    },
    defaults: {
      order: 14,
      w: 6,
      h: 15,
    },
    gridProps: {
      minW: 2,
    },
  },
  timeTracking: null,
  directory: null,
  emailCampaigns: null,
  chat: {
    loadComponent: () => import('@/views/apps/chatv2/widgets/chatWidget.vue' /* webpackChunkName: "ChatWidget" */),
    gridProps: {
      minW: 3,
      minH: 9,
    },
    defaults: {
      order: 3,
      w: 4,
    },
    props: { stretch: true },
    containerProps: { class: 'vh-75' },
  },
  chatV2: {
    loadComponent: () => import('@/views/apps/chatv2/widgets/chatWidget.vue' /* webpackChunkName: "ChatWidget-v2" */),
    gridProps: {
      minW: 3,
      minH: 9,
    },
    defaults: {
      order: 3,
      w: 4,
    },
    props: { stretch: true },
    containerProps: { class: 'vh-75' },
  },
  chatDeprecated: {
    loadComponent: () => import('@/views/apps/chat/widgets/BasicCollectiveChatRoomWidget.vue' /* webpackChunkName: "BasicCollectiveChatRoomWidget" */),
    gridProps: {
      minW: 3,
      minH: 9,
    },
    defaults: {
      order: 3,
      w: 4,
    },
    props: { stretch: true },
    containerProps: { class: 'vh-75' },
  },
  webinars: null,
  videocalls: null,
  videos: {
    loadComponent: () => import('@/views/apps/media/widgets/MediaListWidget.vue' /* webpackChunkName: "MediaListWidget" */),
    // loadComponent: '@/views/apps/media/widgets/MediaListWidget.vue',
    props: { mediaType: MediaType.video },
    defaults: {
      order: 11,
      w: 12,
      h: 11,
    },
    gridProps: {
      minH: 10,
      maxH: 15,
    },
  },
  events: {
    loadComponent: () => import('@/views/apps/events/widgets/EventsHorizontalListWidget.vue' /* webpackChunkName: "events-EventsHorizontalListWidget" */),
    gridProps: {
      minH: 8,
      maxH: 15,
    },
    defaults: {
      order: 7,
      w: 6,
      h: 10,
    },
  },
  eventsRepository: {
    loadComponent: () => import(
      '@/views/apps/community-events/widgets/CommunityEventsListWidget.vue'
      /* webpackChunkName: "CommunityEventsListWidget" */
    ),
    gridProps: {
      minH: 8,
      maxH: 15,
    },
    defaults: {
      order: 20,
      w: 6,
      h: 10,
    },
  },
  membershipEvents: {
    componentsFactory: EventsListWidgetsFactory,
    // componentsFactory: () => import(
    //   '@/views/apps/membershipEvents/widgetFactory/EventsListWidgetsFactory'
    //   /* webpackChunkName: "EventsListWidgetsFactory" */
    // ),
    gridProps: {
      minH: 10,
      maxH: 15,
    },
    defaults: {
      order: 5,
      w: 12,
      h: 11,
    },
  },
  /*   membershipEvents: {
    loadComponent: () =>
      import(
        "@/views/apps/membershipEvents/widgets/MembershipEventsListWidget.vue"
      ),
  }, */
  lists: null,
  tenders: null,
  landing: null,
  welcome: null,
  posts: {
    loadComponent: () => import('@/views/apps/posts/widgets/PostsListWidget.vue' /* webpackChunkName: "PostsListWidget" */),
    gridProps: { minH: 15 },
    containerProps: { class: 'vh-75' },
    defaults: {
      order: 8,
      w: 6,
    },
  },
  map: {
    loadComponent: () => import('@/views/apps/locations/widgets/LocationsMapWidget.vue' /* webpackChunkName: "LocationsMapWidget" */),
    containerProps: { class: 'vh-75' },
  },
  communication: null, // communication app is deprecated
  storage: {
    loadComponent: () => import('@/views/apps/storage/widgets/StorageListWidget.vue' /* webpackChunkName: "StorageListWidget" */),
    props: { mediaType: MediaType.storage },
    gridProps: {
      minH: 8,
      maxH: 15,
    },
    defaults: {
      h: 9,
      w: 12,
    },
  },
  suggestions: null,
  courses: {
    loadComponent: () => import('@/views/apps/courses/widgets/CoursesListWidget.vue' /* webpackChunkName: "CoursesListWidget" */),
    /*     props: {
      collectiveFilter: CollectiveFilter.course,
      id: "courses-list",
    }, */
    defaults: {
      h: 10,
    },
    gridProps: {
      minH: 8,
      maxH: 15,
    },
  },
  subcommunities: {
    loadComponent: () => import('@/views/apps/collectives/widgets/CollectivesListWidget.vue' /* webpackChunkName: "CollectivesListWidget" */),
    props: {
      collectiveFilter: CollectiveFilter.subcommunity,
      id: 'subcommunities-list',
    },
    defaults: {
      h: 10,
    },
    gridProps: {
      minH: 8,
      maxH: 15,
    },
  },
  invoices: null,
  inventory: null,
  rooms: {
    loadComponent: () => import('@/views/apps/rooms/widgets/RoomsListWidget.vue' /* webpackChunkName: "RoomsListWidget" */),
    defaults: {
      order: 6,
      w: 12,
      h: 9,
    },
    gridProps: {
      minH: 9,
      maxH: 15,
    },
  },
  conference: {
    loadComponent: () => import('@/views/apps/conference/ConferenceWidget.vue'  /* webpackChunkName: "ConferenceWidget" */),
    gridProps: {
      minH: 5,
      maxH: 30,
    },
    defaults: {
      order: 2,
      w: 8,
      h: 16,
    },
  },
  marketplace: null,
  calendar: null,
  documents: {
    loadComponent: () => import('@/views/apps/media/widgets/MediaListWidget.vue'  /* webpackChunkName: "MediaListWidget" */),
    props: { mediaType: MediaType.document },
    defaults: {
      order: 11,
      w: 12,
      h: 11,
    },
    gridProps: {
      minH: 10,
      maxH: 15,
    },
  },
  spreadsheets: {
    loadComponent: () => import('@/views/apps/media/widgets/MediaListWidget.vue' /* webpackChunkName: "MediaListWidget" */),
    props: { mediaType: 'spreadsheet' },
    defaults: {
      order: 11,
      w: 12,
      h: 11,
    },
    gridProps: {
      minH: 10,
      maxH: 15,
    },
  },
  notes: null,
  photos: {
    loadComponent: () => import('@/views/apps/media/widgets/MediaListWidget.vue' /* webpackChunkName: "MediaListWidget" */),
    props: { mediaType: MediaType.photo },
    defaults: {
      order: 11,
      w: 12,
      h: 11,
    },
    gridProps: {
      minH: 10,
      maxH: 15,
    },
  },
  audios: {
    loadComponent: () => import('@/views/apps/media/widgets/MediaListWidget.vue' /* webpackChunkName: "MediaListWidget" */),
    props: { mediaType: MediaType.audio },
    defaults: {
      order: 11,
      w: 12,
      h: 11,
    },
    gridProps: {
      minH: 10,
      maxH: 15,
    },
  },
  faqs: {
    loadComponent: () => import('@/views/apps/faqs/widgets/FaqAccordionWidget.vue' /* webpackChunkName: "FaqAccordionWidget" */),
    defaults: {
      order: 12,
      w: 12,
    },
  },
  organizations: {
    componentsFactory: OrganizationsListWidgetsFactory,
    // componentsFactory: () => import('@/views/apps/organization/widgetFactory/OrganizationsListWidgetsFactory' /* webpackChunkName: "OrganizationsListWidgetsFactory" */),
    props: {
      enableCardSize: 'xl',
      listingType: 0,
    },
    defaults: {
      order: 15,
      w: 6,
      h: 15,
    },
  },
  societies: {
    loadComponent: () => import('@/views/apps/societies/widgets/SocietiesTableWidget.vue' /* webpackChunkName: "SocietiesTableWidget" */),
    props: {
      enableCardSize: 'xl',
      listingType: 0,
    },
    defaults: {
      order: 15,
      w: 6,
      h: 15,
    },
  },
  aids: null,
  people: {
    componentsFactory: PeopleListWidgetsFactory,
    // componentsFactory: () => import('@/views/apps/people/widgetFactory/PeopleListWidgetsFactory' /* webpackChunkName: "PeopleListWidgetsFactory" */),
    gridProps: {
      minH: 10,
      maxH: 15,
    },
    defaults: {
      order: 5,
      w: 12,
      h: 11,
    },
  },
  elements: {
    componentsFactory: ElementsListWidgetsFactory,
    // componentsFactory: () => import('@/views/apps/elements/widgetFactory/ElementsListWidgetsFactory' /* webpackChunkName: "ElementsListWidgetsFactory" */),
    defaults: {
      w: 6,
      h: 10,
    },
  },
  books: null,
  trademarks: null,
  grants: null,
  places: null,
  vehicles: null,
  equipments: null,
  dataImports: null,
  deals: null,
  sections: {
    loadComponent: () => import('@/views/apps/collectives/widgets/CollectivesListWidget.vue' /* webpackChunkName: "Sections-CollectivesListWidget" */),
    props: {
      id: 'sections-list',
      collectiveFilter: CollectiveFilter.section,
    },
    defaults: {
      order: 13,
      w: 12,
      h: 10,
    },
    gridProps: {
      minH: 8,
      maxH: 15,
    },
  },
  meetings: null,
  matchmaking: null,
  liveStreaming: {
    loadComponent: () => import('@core/components/streaming/StreamingWidget.vue' /* webpackChunkName: "StreamingWidget" */),
    gridProps: {
      minH: 5,
      maxH: 30,
    },
    defaults: {
      order: 2,
      w: 8,
      h: 16,
    },
  },
  offers: null,
  orders: null,
  deliveryNotes: null,
  presentations: {
    loadComponent: () => import('@/views/apps/media/widgets/MediaListWidget.vue'  /* webpackChunkName: "MediaListWidget" */),
    props: { mediaType: MediaType.presentation },
    defaults: {
      order: 9,
      w: 12,
      h: 11,
    },
    gridProps: {
      minH: 8,
      maxH: 15,
    },
  },
  profile: null,
  polls: null,
  exams: null,
  members: {
    loadComponent: () => import('@/views/apps/member/widgets/MembersListWidget.vue' /* webpackChunkName: "MembersListWidget" */),
    props: {
      perPage: 7,
    },
    defaults: {
      order: 14,
      w: 6,
      h: 15,
    },
    gridProps: {
      minW: 2,
    },
  },
  contacts: {
    loadComponent: () => import('@/views/apps/contacts/widgets/ContactsListWidget.vue' /* webpackChunkName: "ContactsListWidget" */),
    props: {
      perPage: 7,
    },
    defaults: {
      order: 14,
      w: 6,
      h: 15,
    },
    gridProps: {
      minW: 2,
    },
  },
  forms: null,
  communities: null,
  explore: null,
  intranet: null,
  cycleEvents: null,
  components: null,
  agenda: {
    loadComponent: () => import('@/views/apps/events/widgets/EventsAgendaWidget.vue' /* webpackChunkName: "EventsAgendaWidget" */),
    containerProps: { class: 'vh-75' },
    defaults: {
      order: 4,
      w: 12,
      h: 14,
    },
  },
  organizationsByType: {
    loadComponent: () => import(
      '@/views/apps/organization/widgets/OrganizationsByTypeTableWidget.vue' /* webpackChunkName: "OrganizationsByTypeTableWidget" */
    ),
    props: {
      enableCardSize: 'xl',
      listingType: 0,
    },
    defaults: {
      order: 16,
      w: 6,
      h: 17,
    },
  },
  entities: {
    componentsFactory: EntitiesListWidgetsFactoryWithoutTypes,
    // componentsFactory: () => import('@/views/apps/entities/widgetFactory/EntitiesListWidgetsFactoryWithoutTypes' /* webpackChunkName: "EntitiesListWidgetsFactoryWithoutTypes" */),
    gridProps: {
      minH: 8,
      maxH: 15,
    },
    defaults: {
      order: 17,
      w: 12,
      h: 8,
    },
  },
  chatIndividual: null,
  about: {
    loadComponent: () => import('@core/components/about-event/AboutEvent.vue' /* webpackChunkName: "AboutEvent" */),
    defaults: {
      order: 1,
      h: 5,
      w: 12,
    },
  },
  twitterFeed: {
    loadComponent: () => import('@/views/apps/twitter/widgets/TwitterFeedWidget.vue' /* webpackChunkName: "TwitterFeedWidget" */),
    containerProps: { class: 'vh-75' },
  },
  kahoot: {
    loadComponent: () => import('@/views/apps/kahoot/KahootWidget.vue' /* webpackChunkName: "KahootWidget" */),
    containerProps: { class: 'vh-100' },
  },
  mentimeter: {
    loadComponent: () => import('@/views/apps/mentimeter/MentimeterWidget.vue' /* webpackChunkName: "MentimeterWidget" */),
    containerProps: { class: 'vh-100' },
  },
  quizlet: {
    loadComponent: () => import('@/views/apps/quizlet/QuizletWidget.vue' /* webpackChunkName: "QuizletWidget" */),
    containerProps: { class: 'vh-100' },
  },
  slido: {
    loadComponent: () => import('@/views/apps/slido/SlidoWidget.vue' /* webpackChunkName: "SlidoWidget" */),
    containerProps: { class: 'vh-100' },
  },
  framevr: {
    loadComponent: () => import('@/views/apps/framevr/FramevrWidget.vue' /* webpackChunkName: "FramevrWidget" */),
    containerProps: { class: 'vh-100' },
  },
  questions: {
    loadComponent: () => import('@/views/apps/questions/widgets/QuestionListWidget.vue' /* webpackChunkName: "QuestionListWidget" */),
    defaults: {
      h: 8,
      w: 12,
    },
  },
  votes: {
    loadComponent: () => import('@/views/votes/VotesWidget.vue'  /* webpackChunkName: "VotesWidget" */),
    defaults: {
      h: 8,
      w: 12,
    },
  },
  products: {
    loadComponent: () => import('@/views/apps/products/widgets/ProductsListWidget.vue'  /* webpackChunkName: "ProductsListWidget" */),
    gridProps: {
      minH: 10,
      maxH: 15,
    },
    defaults: {
      h: 15,
      w: 12,
    },
  },
  grants: {
    loadComponent: () => import('@/views/apps/grants/widgets/GrantsListWidget.vue' /* webpackChunkName: "GrantsListWidget" */),
    gridProps: {
      minH: 10,
      maxH: 15,
    },
    defaults: {
      h: 12,
      w: 12,
    },
  },
  projects: {
    loadComponent: () => import('@/views/apps/projects/widgets/ProjectListWidget.vue' /* webpackChunkName: "ProjectListWidget" */),
    defaults: {
      h: 8,
      w: 12,
    },
  },
  successStories: {
    loadComponent: () => import('@/views/apps/success-stories/SuccessStoriesListWidget.vue' /* webpackChunkName: "SuccessStoriesListWidget" */),
    defaults: {
      h: 8,
      w: 12,
    },
  },
  blogs: {
    loadComponent: () => import('@/views/apps/blogs/widgets/BlogListWidget.vue' /* webpackChunkName: "BlogListWidget" */),
    defaults: {
      h: 8,
      w: 12,
    },
  },
  articles: {
    loadComponent: () => import('@/views/apps/articles/widgets/ArticleListWidget.vue' /* webpackChunkName: "ArticleListWidget" */),
    defaults: {
      h: 8,
      w: 12,
    },
  },
  auditorium: {
    loadComponent: () => import('@/views/apps/mainroom/widgets/MainRoomWidget.vue' /* webpackChunkName: "MainRoomWidget" */),
    gridProps: {
      minH: 5,
    },
    defaults: {
      h: 9,
      w: 12,
      order: 1,
    },
  },
  /*   challenges: {
    loadComponent: () =>
      import("@/views/apps/challenges/widgets/ChallengesListWidget.vue"),
    defaults: {
      h: 8,
      w: 12,
    },
  }, */
  challenges: {
    componentsFactory: ChallengesListWidgetsFactory,
    // componentsFactory: () => import('@/views/apps/challenges/widgetFactory/ChallengesListWidgetFactory' /* webpackChunkName: "ChallengesListWidgetsFactory" */),
    defaults: {
      h: 8,
      w: 12,
    },
  },
  ideas: {
    loadComponent: () => import('@/views/apps/ideas/widgets/IdeasListWidget.vue' /* webpackChunkName: "IdeasListWidget" */),
    defaults: {
      h: 8,
      w: 12,
    },
  },
  jobs: {
    loadComponent: () => import('@/views/apps/jobs/widgets/JobsListWidget.vue' /* webpackChunkName: "JobsListWidget" */),
    gridProps: {
      minH: 8,
      maxH: 15,
    },
    defaults: {
      h: 9,
      w: 12,
    },
  },
  forums: {
    loadComponent: () => import('@/views/apps/forums/widgets/ForumsListWidget.vue' /* webpackChunkName: "ForumsListWidget" */),
    defaults: {
      h: 6,
      w: 12,
    },
  },
  services: {
    loadComponent: () => import('@/views/apps/services/widgets/ServicesListWidget.vue' /* webpackChunkName: "ServicesListWidget" */),
    gridProps: {
      minH: 10,
      maxH: 15,
    },
    defaults: {
      h: 15,
      w: 12,
    },
  },
  membersByType: {
    componentsFactory: MembersListWidgetsFactory,
    // componentsFactory: () => import('@/views/apps/member/widgetFactory/MembersListWidgetsFactory' /* webpackChunkName: "MembersListWidgetsFactory" */),
    defaults: {
      order: 14,
      w: 6,
      h: 15,
    },
  },
});

export default AppsComponentsMap;
