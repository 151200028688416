import Vue from 'vue';
import store from '@/store';
import Service from '@/config/service-identifiers';
import { AppID, AppIDLUT } from '@copernicsw/community-common';
import { enumToInvertedObject } from '@core/utils/utils';
import AppsComponentsMap from '@/store/enabled-apps/apps-components-map';

/**
 * Store module for the enabled app
 */
export default {
  namespaced: true,
  state: {
    apps: [],
    enabledApps: {},
    componentsLoaders: {},
    savedLayout: [],
    editedLayout: null,
    colNum: 12,
    defaultHeight: 10,
    defaultWidth: 6,
    isEditingLayout: false,
    isLayoutEdited: false,
  },
  getters: {
    /**
     * Returns a map with the name of the enabled apps and it's configuration as key.
     */
    enabledApps({ enabledApps }) {
      return enabledApps;
    },
    componentsLoaders({ componentsLoaders }) {
      return componentsLoaders;
    },
    layout({ isEditingLayout, editedLayout, savedLayout }) {
      return isEditingLayout ? editedLayout : savedLayout;
    },
    mobileLayout(_state, { layout }) {
      return [...layout].sort((a, b) => (
        (a.x * a.x + a.y * a.y) - (b.x * b.x + b.y * b.y)
      ));
    },
    savedLayout({ savedLayout }) {
      return savedLayout;
    },
    isEditingLayout({ isEditingLayout }) {
      return isEditingLayout;
    },
    isLayoutEdited({ isLayoutEdited }) {
      return isLayoutEdited;
    },
  },
  mutations: {
    setApps(state, apps) {
      state.apps = apps;
    },
    setEnabledApps(state, enabledApps) {
      state.enabledApps = enabledApps;
    },
    setComponentsLoaders(state, componentsLoaders) {
      state.componentsLoaders = componentsLoaders;
    },
    setSavedLayout(state, layout) {
      state.savedLayout = layout;
      state.editedLayout = JSON.parse(JSON.stringify(state.savedLayout));
    },
    updateLayout(state, layout) {
      if (!layout?.length) {
        return;
      }

      if(layout.length < state.editedLayout?.length){
        Vue.set(state, "editedLayout", []);
      }

      layout.forEach(({
        x, y, h, w, appKey,
      }) => {
        let appIndex = state.editedLayout.findIndex((item) => item.appKey === appKey);
        if(appIndex === -1){
          const item = layout.find((item) => item.appKey === appKey);
          if(!item){
            return;
          }
          state.editedLayout.push(item);
          appIndex = state.editedLayout.findIndex((item) => item.appKey === appKey);
        }
        Vue.set(state.editedLayout[appIndex], 'x', x);
        Vue.set(state.editedLayout[appIndex], 'y', y);
        Vue.set(state.editedLayout[appIndex], 'h', h);
        Vue.set(state.editedLayout[appIndex], 'w', w);

      });
    },
    updateAppCustomName(state, { appId, customNameTable, customAppId }) {
      const appNameKey = appId === 120 ? 'contacts' : AppIDLUT[appId];

      let appIndex;

      if(appNameKey === "entities" || appNameKey === "people"){
        appIndex = state.editedLayout.findIndex(({ appKey }) => appKey === customAppId);
      } else if (appNameKey === "organizations") {
        appIndex = state.editedLayout.findIndex(({ appKey }) => appKey === 'organization-null');
        return state.editedLayout[appIndex].customizationName = customNameTable;
      } else {
        appIndex = state.editedLayout.findIndex(({ appKey }) => appKey === appNameKey);
      }

      if (!state.editedLayout[appIndex]) return;

      Vue.set(state.editedLayout[appIndex], 'customizationName', customNameTable);
    },
    toggleEditLayout(state) {
      state.isEditingLayout = !state.isEditingLayout;
    },
    cancelEditLayout(state) {
      state.isEditingLayout = false;
      state.editedLayout = JSON.parse(JSON.stringify(state.savedLayout));
    },
    confirmEditLayout(state) {
      state.savedLayout = JSON.parse(JSON.stringify(state.editedLayout));
      state.isEditingLayout = false;
    },
  },
  actions: {
    async fetchEnabledApps(ctx, force) {
      if(!ctx.rootGetters.currentCollective){
        return;
      }

      const requestConfig = { 
        params: { 
          communitySlug: ctx.rootGetters.currentCollective?.slug, 
          isApps: true 
        } 
      };
      let [layoutResponse] = await Promise.all([
        store.$service[Service.BackendClient].get('layout', requestConfig),
      ]);

      if(ctx.rootGetters.apps == null){
        
      }

      if(Object.values(ctx.rootGetters.apps).length === 0||force){
  
      }
      const appsResponse = ctx.rootGetters.apps;

      if(layoutResponse == null && ctx.rootGetters.currentCollective != null){
        layoutResponse = {data: {layout: ctx.rootGetters.currentCollective.layout}}
      }
      
      const layoutMap = (layoutResponse.data.layout || []).reduce((reducedLayout, appLayout, index) => ({
        ...reducedLayout,
        [appLayout.appKey]: {
          ...appLayout,
          order: index + 1,
        },
      }), {});

      const AppIDObject = enumToInvertedObject(AppID);
      
      // TODO: remove this Workaround.The apps to show come via API, we don't have to use community common, all this file has to be modified
      AppIDObject[70] = 'auditorium';
      AppIDObject[72] = 'eventsRepository';
      AppIDObject[75] = 'successStories';
      AppIDObject[78] = 'services';
      AppIDObject[38] = 'grants';
      AppIDObject[120] = 'contacts';
      AppIDObject[123] = 'societies';
     // AppIDObject[96] = 'membershipEvents';

      const { apps } = appsResponse;
      ctx.commit('setApps', apps);

      const appsMap = {};
      const layouts = {};
      layouts.elements = {
        name: 'Elements'
      }
      await Promise.all(Object.entries(apps).map(async ([appId, app]) => {
        if (!AppIDObject[appId]) {
          return;
        }
        const appKey = AppIDObject[appId];
        if(app.inLayout === true){
          layouts[appKey] = app;
        }
        appsMap[appKey] = app;

        
      }));
      ctx.commit('setEnabledApps', appsMap);

      const layoutArray = [];
      const componentsLoaders = [];
      await Promise.all(
        Object.entries(layouts)
          .filter(([appKey]) => !!AppsComponentsMap[appKey])
          .map(async ([appKey, app]) => {
            const { loadComponent, componentsFactory, ...data } = AppsComponentsMap[appKey];
            if (loadComponent) {
              Vue.set(componentsLoaders, appKey, async () => (await loadComponent()).default);
              layoutArray.push({
                appKey,
                app,
                layout: layoutMap[appKey] || null,
                data,
              });
            }

            if (componentsFactory && typeof(componentsFactory) === "function") {
              
              const componentsMap = await componentsFactory()
              await Promise.all(Object.entries(componentsMap).map(async ([widgetKey, widget]) => {
                const { loadComponent: innerLoadComponent, ...innerData } = widget;
                if (innerLoadComponent) {
                  Vue.set(componentsLoaders, widgetKey, async () => (await innerLoadComponent()).default);
                  layoutArray.push({
                    appKey: widgetKey,
                    app,
                    layout: layoutMap[widgetKey] || null,
                    data: { ...data, ...innerData },
                  });
                }
              }));
            }
          }),
      );

      const hasSavedLayout = (layoutResponse.data.layout || []).length > 0;

      const finalLayout = layoutArray
        .sort((app1, app2) => {
          let app1Order;
          let app2Order;
          if (hasSavedLayout) {
            // new apps in a saved layout will be placed at the top.
            app1Order = app1.layout?.order || 999;
            app2Order = app2.layout?.order || 999;
          } else {
            // apps without default order go to the bottom.
            app1Order = app1.data?.defaults?.order || 999;
            app2Order = app2.data?.defaults?.order || 999;
          }

          return app1Order - app2Order;
        })
        .reduce((
          storedLayout,
          {
            appKey, app, data, layout,
          },
        ) => {
          const lastItem = storedLayout[storedLayout.length - 1]
          || {
            x: 0, y: 0, w: 0, h: 0,
          };

          let x = layout?.x;
          if (x === undefined) {
            x = (lastItem.x + lastItem.w >= ctx.state.colNum ? 0 : lastItem.x + lastItem.w);
          }

          const h = (layout || data.defaults)?.h
            || (data.gridProps?.minH ? data.gridProps.minH : ctx.state.defaultHeight);

          storedLayout.push({
            x,
            y: layout?.y || 0,
            w: (layout || data.defaults)?.w || ctx.state.defaultWidth,
            h,
            i: appKey,
            customizationName: layout?.customizationName,
            app,
            appKey,
            ...data,
          });

          return storedLayout;
        }, []);

      ctx.commit('setComponentsLoaders', componentsLoaders);
      ctx.commit('setSavedLayout', finalLayout.sort((a, b) => a.y - b.y ));
    },
    async saveLayout({ state, commit, rootGetters }) {
      await store.$service[Service.BackendClient].post(
        'layout',
        {
          communitySlug: rootGetters.currentCollective.slug,
          keys: rootGetters.elementsKeysList.unpaginated.length > 0 ? rootGetters.elementsKeysList.unpaginated : null,
          layout: state.editedLayout.map(({
            x, y, h, w, appKey, customizationName,
          }) => ({
            x, y, h, w, appKey, customizationName,
          })),
        },
      );
      commit('confirmEditLayout');
      commit("SET_ITEM_LITERAL", 
        {
          itemType: "elementsKeysList",
          data: {
            paginated: {},
            unpaginated: [],
          },
        }, 
        {
          root: true
        }
      );
      state.isLayoutEdited = true;
      console.log('Changing state.isLayoutEdited to true');
    },
  },
};
