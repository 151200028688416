export const ENABLED_APPS_STORE_MODULE_NAME = 'enabled-apps';

export const ENABLED_APPS_MUTATIONS = {
  updateAppCustomName: `${ENABLED_APPS_STORE_MODULE_NAME}/updateAppCustomName`,
  toggleEditLayout: `${ENABLED_APPS_STORE_MODULE_NAME}/toggleEditLayout`,
  cancelEditLayout: `${ENABLED_APPS_STORE_MODULE_NAME}/cancelEditLayout`,
  updateLayout: `${ENABLED_APPS_STORE_MODULE_NAME}/updateLayout`,
};

export const ENABLED_APPS_ACTIONS = {
  fetchEnabledApps: `${ENABLED_APPS_STORE_MODULE_NAME}/fetchEnabledApps`,
  enableEditLayout: `${ENABLED_APPS_STORE_MODULE_NAME}/enableEditLayout`,
  saveLayout: `${ENABLED_APPS_STORE_MODULE_NAME}/saveLayout`,
};

export const ENABLED_APPS_GETTERS = {
  enabledApps: `${ENABLED_APPS_STORE_MODULE_NAME}/enabledApps`,
  componentsLoaders: `${ENABLED_APPS_STORE_MODULE_NAME}/componentsLoaders`,
  layout: `${ENABLED_APPS_STORE_MODULE_NAME}/layout`,
  savedLayout: `${ENABLED_APPS_STORE_MODULE_NAME}/savedLayout`,
  mobileLayout: `${ENABLED_APPS_STORE_MODULE_NAME}/mobileLayout`,
  isEditingLayout: `${ENABLED_APPS_STORE_MODULE_NAME}/isEditingLayout`,
  isLayoutEdited: `${ENABLED_APPS_STORE_MODULE_NAME}/isLayoutEdited`,
};
